@import "/node_modules/typeface-montserrat/index.css";

.p-datatable.p-datatable-striped-custon-relatorio-espelho .p-datatable-tbody>tr.p-row-odd {
  background: #c5c5c5;
}

.p-datatable.p-datatable-striped-custon-relatorio-espelho .p-datatable-tbody>tr.p-row-odd.p-highlight {
  background: #c5c5c5;
  color: #c5c5c5;
}

.p-datatable.p-datatable-striped-custon-relatorio-espelho .p-datatable-tbody>tr.p-row-odd.p-highlight .p-row-toggler {
  color: #c5c5c5;
}

.p-datatable.p-datatable-striped-custon-relatorio-espelho .p-datatable-tbody>tr.p-row-odd.p-highlight .p-row-toggler:hover {
  color: #c5c5c5;
}

.p-datatable.p-datatable-striped-custon-relatorio-espelho .p-datatable-tbody>tr.p-row-odd+.p-row-expanded {
  background: #c5c5c5;
}

.p-datatable-columns-ponto table th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.fa-bullseye {
  animation: fa-bullseye 5s ease infinite;
}

.inline-block-child {
  display: inline-block;
}

@keyframes fa-bullseye {
  0% {
    transform: scale(1);
  }

  5% {
    transform: scale(1.25);
  }

  20% {
    transform: scale(1);
  }

  30% {
    transform: scale(1);
  }

  35% {
    transform: scale(1.25);
  }

  50% {
    transform: scale(1);
  }

  55% {
    transform: scale(1.25);
  }

  70% {
    transform: scale(1);
  }
}

.datatable-responsive-demo .vencido {
  background-color: #ffd5d5 !important;
}

.datatable-responsive-demo .pago {
  background-color: #80d4a4 !important;
}

.datatable-responsive-demo .pontoInconsistente {
  font-weight: 700;
  color: #FF5252;
}

.p-datatable .p-datatable-header {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
  font-size: 13px;
}

.p-fieldset-legend>a,
.p-fieldset-legend>span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9pt !important;
}

.p-fieldset .p-fieldset-legend {
  padding: 0.5rem !important;
  border: 1px solid #dee2e6;
  color: #495057;
  background: #f8f9fa;
  font-weight: 600;
  border-radius: 3px;
}

.p-datatable .p-datatable-thead>tr>th {
  font-size: 13px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
  line-height: 1.5;
  padding: 0.5rem 0.5rem !important;
}

.p-datatable .p-datatable-tbody>tr>td {
  font-size: 13px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
  line-height: 1.5;
  padding: 0.5rem 0.5rem !important;
}


.p-datatable-tbody>tr>td>.p-column-title {
  display: none;
}

/*
.p-datatable-responsive-demo .p-datatable-wrapper .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}
*/

@media screen and (max-width: 40em) {

  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-thead>tr>th,
  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tfoot>tr>td {
    display: none !important;
  }

  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody>tr>td {
    text-align: left;
    display: block;
    width: 100% !important;
    float: left;
    clear: left;
    border: 0 none;
  }

  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody>tr>td .p-column-title {
    padding: .4rem;
    min-width: 30%;
    display: inline-block;
    margin: -.4em 1em -.4em -.4rem;
    font-weight: bold;
  }

  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody>tr>td:last-child {
    border-bottom: 1px solid var(--surface-d);
  }

}

#root {
  height: 100%;
}

body {
  font-style: normal !important;
  background: rgb(247, 249, 252);
}

.MuiCardHeader-action .MuiIconButton-root {
  padding: 4px;
  width: 28px;
  height: 28px;
}

.p-datatable .p-paginator-bottom {
  border: 0px !important;
}


.MuiContainer-root {
  padding: 1px !important;
}

.MuiToolbar-regular {
  min-height: 50px !important;
}

.makeStyles-divPrincipal-5 {
  padding: 0 50px 50px 50px !important;
}

.MuiFormControl-root2 {
  display: block !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

  .margin-01 {
    margin-top: -85px !important;
  }

  .img-caroucel {
    width: 100% !important;
    height: 100% !important;
  }

  .img-2 {
    width: 180px !important;
  }

  .w-85 {
    width: 85% !important;
  }

  .w-100 {
    width: 100% !important;
  }

  .MuiGrid-grid-xs-7 {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }

  .MuiTypography-h3 {
    font-size: 2.3rem !important;
  }

  .MuiTypography-h6 {
    font-size: 22px;
    line-height: 1.6;
    letter-spacing: -0.05px;
  }

  .displayNone {
    display: none;
  }

  .MuiBadge-badge {
    margin-top: 7px !important;
  }


  .paddingX-10 {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .paddingX-35 {
    padding: 10px !important;
  }

  .paddingY-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }


}

.datatable-rowexpansion-demo {
  padding: 1rem;
}

.warnColor {
  color: crimson,

}


@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700");

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.svg-funnel-js {
  font-family: Open Sans, sans-serif;
}

.svg-funnel-js .svg-funnel-js__container {
  width: 100%;
  height: 100%;
}

.svg-funnel-js .svg-funnel-js__labels {
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0px;
  flex: 1 1 0;
  position: relative;
}

.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__value {
  font-size: 24px;
  /* color: #fff; */
  line-height: 18px;
  margin-bottom: 6px;
}

.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__title {
  font-size: 12px;
  font-weight: 700;
  /* color: #21ffa2; */
}

.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__percentage {
  font-size: 16px;
  font-weight: 700;
  /* color: #9896dc; */
}

.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__segment-percentages {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  left: 0;
  padding: 8px 24px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: rgba(8, 7, 48, 0.8);
  margin-top: 24px;
  opacity: 0;
  -webkit-transition: opacity 0.1s ease;
  transition: opacity 0.1s ease;
  cursor: default;
}

.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__segment-percentages ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__segment-percentages ul li {
  font-size: 13px;
  line-height: 16px;
  color: #fff;
  margin: 18px 0;
}

.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__segment-percentages ul li .percentage__list-label {
  font-weight: 700;
  /* color: #05df9d; */
}

.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label:hover .label__segment-percentages {
  opacity: 1;
}

.svg-funnel-js:not(.svg-funnel-js--vertical) {
  padding-top: 64px;
  padding-bottom: 16px;
}

.svg-funnel-js:not(.svg-funnel-js--vertical) .svg-funnel-js__label {
  padding-left: 24px;
}

.svg-funnel-js:not(.svg-funnel-js--vertical) .svg-funnel-js__label:not(:first-child) {
  border-left: 1px solid #9896dc;
}

.svg-funnel-js.svg-funnel-js--vertical {
  padding-left: 120px;
  padding-right: 16px;
}

.svg-funnel-js.svg-funnel-js--vertical .svg-funnel-js__label {
  padding-top: 24px;
}

.svg-funnel-js.svg-funnel-js--vertical .svg-funnel-js__label:not(:first-child) {
  border-top: 1px solid #9896dc;
}

.svg-funnel-js.svg-funnel-js--vertical .svg-funnel-js__label .label__segment-percentages {
  margin-top: 0;
  margin-left: 106px;
  width: calc(100% - 106px);
}

.svg-funnel-js.svg-funnel-js--vertical .svg-funnel-js__label .label__segment-percentages .segment-percentage__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.svg-funnel-js .svg-funnel-js__subLabels {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 24px;
  position: absolute;
  width: 100%;
  left: 0;
}

.svg-funnel-js .svg-funnel-js__subLabels .svg-funnel-js__subLabel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 12px;
  /* color: #fff; */
  line-height: 16px;
}

.svg-funnel-js .svg-funnel-js__subLabels .svg-funnel-js__subLabel:not(:first-child) {
  margin-left: 16px;
}

.svg-funnel-js .svg-funnel-js__subLabels .svg-funnel-js__subLabel .svg-funnel-js__subLabel--color {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 2px 8px 2px 0;
}

.svg-funnel-js {
  display: inline-block;
  position: relative;
}

.svg-funnel-js svg {
  display: block;
}

.svg-funnel-js .svg-funnel-js__labels {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.svg-funnel-js.svg-funnel-js--vertical .svg-funnel-js__labels {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}